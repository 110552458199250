<template>
  <div>
    <h4>Export customers to your mailchimp account for email campaigns.</h4>
    <h4>
      <small>Follow these steps to connect to your mailchimp account</small>
    </h4>
    <hr />

    <!-- 				<h4><small>METHOD - 1</small></h4>	 -->
    <h4 class="margin-bottom">Mailchimp Instructions</h4>
    <div>
      <b>Step 1:</b><br />
      <label class="margin-bottom"
        >Click on the Activate button at the right side of the screen.
      </label>
    </div>
    <div>
      <b>Step 2:</b><br />
      <label class="margin-bottom"
        >Wait for the pop-up to appear and login to your Mailchimp account.
      </label>
    </div>
    <div>
      <b>Step 3:</b><br />
      <label class="margin-bottom"
        >Choose the audience list from drop down and click on save button.
      </label>
    </div>
    <div>
      <b>Note:</b><br />
      <label class="margin-bottom"
        >If you change the email address of existing customer, a new customer
        contact will be created in Mailchimp audience list &amp; the old contact
        remains same.
      </label>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12">
        <div class="card form-body">
          <div class="card-body">
            <v-sms></v-sms>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSms from "@/pages/backend/settings/map_n_sms_config/sms";
export default {
  components: {
    vSms
  }
};
</script>
